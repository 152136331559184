import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`One of the things I noticed while working at Moltin was that people consume commerce APIs in all kinds of ways.`}</p>
    <p>{`So it wasn't too long before I started to look at ways I could work with the `}<a parentName="p" {...{
        "href": "https://commercejs.com"
      }}>{`Commerce.js`}</a>{` inside my lambdas, without bringing the full Commerce.js SDK along for the ride.`}</p>
    <p>{`After all, fewer dependencies the better! 😅`}</p>
    <p>{`If you're extending the API behind Commerce.js (Chec) with your own webhooks, it's quite common you'll need to write data to Commerce.js, or fetch to sync elsewhere.`}</p>
    <p>{`Since Commerce.js doesn't support the create, update, or delete endpoints for most of its resources `}{`—`}{` and why would it? It's a frontend commerce SDK.`}</p>
    <p>{`I took it upon myself to build a simple wrapper that handles authentication, and parsing JSON, using the `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/cross-fetch"
      }}><inlineCode parentName="a">{`cross-fetch`}</inlineCode></a>{` polyfill.`}</p>
    <p>{`You can get started by installing the `}<a parentName="p" {...{
        "href": "http://npmjs.com/package/chec-request"
      }}><inlineCode parentName="a">{`chec-request`}</inlineCode></a>{` dependency using Yarn or NPM:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install chec-request
`}</code></pre>
    <p>{`Once installed, you can create a "client" you can pass around if you're making more than one request, or if it's just a one off, use the `}<inlineCode parentName="p">{`request`}</inlineCode>{` function.`}</p>
    <h3>{`With client`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { Chec, request } = require("chec-request");

const commerce = new Chec("CHEC_API_TOKEN");

commerce.get("products").then((products) => console.log(products));
`}</code></pre>
    <h3>{`With request`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`request("products", { token: "CHEC_API_TOKEN" }).then((data) =>
  console.log(data)
);
`}</code></pre>
    <h2>{`Examples`}</h2>
    <p>{`Some API endpoints require a `}<strong parentName="p">{`secret key`}</strong>{`, you should check the `}<a parentName="p" {...{
        "href": "https://commercejs.com/docs/api"
      }}>{`API documentation`}</a>{` and pass the applicable `}<inlineCode parentName="p">{`TOKEN`}</inlineCode>{`.`}</p>
    <h3><inlineCode parentName="h3">{`GET`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { Chec, request } = require("chec-request");

const commerce = new Chec("CHEC_API_TOKEN");

commerce
  .get("products/prod_f89398fs489g")
  .then((products) => console.log(products));

// Or with a simple request

request("products", {
  token: "CHEC_API_TOKEN",
}).then((products) => console.log(products));
`}</code></pre>
    <h3><inlineCode parentName="h3">{`GET`}</inlineCode>{` with params`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { Chec, request } = require("chec-request");

const commerce = new Chec("CHEC_API_TOKEN");

commerce
  .get("products", {
    limit: 5,
    category_slug: "accessories",
  })
  .then((products) => console.log(products))
  .catch((err) => console.log(err));

// Or with a simple request

request("products", {
  token: "CHEC_API_TOKEN",
  params: {
    limit: 5,
    category_slug: "accessories",
  },
})
  .then((products) => console.log(products))
  .catch((err) => console.log(err));
`}</code></pre>
    <h3><inlineCode parentName="h3">{`POST`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { Chec, request } = require("chec-request");

const data = {
  code: "RHKZKU71WG",
  type: "fixed",
  value: "49.95",
  limit_quantity: 1,
  quantity: 10,
  expires: 0,
};

const commerce = new Chec("CHEC_API_TOKEN");

commerce.post("discounts", data).then((discount) => console.log(discount));

// Or with a simple request

request("discounts", {
  token: "CHEC_API_TOKEN",
  method: "POST",
  data,
}).then((data) => console.log(data));
`}</code></pre>
    <h3><inlineCode parentName="h3">{`PUT`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { Chec, request } = require("chec-request");

const data = {
  customer: {
    firstname: "Jamie",
    lastname: "Barton",
  },
};

const commerce = new Chec("CHEC_API_TOKEN");

commerce.put("orders/orderId", data).then((discount) => console.log(discount));

// Or with a simple request

request("orders/orderId", {
  token: "CHEC_API_TOKEN",
  method: "PUT",
  data,
}).then((data) => console.log(data));
`}</code></pre>
    <h3><inlineCode parentName="h3">{`DELETE`}</inlineCode></h3>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const { Chec, request } = require("chec-request");

const commerce = new Chec("CHEC_API_TOKEN");

commerce.delete("discounts/RHKZKU71WG").then((res) => console.log(res));

// Or with a simple request

request("discounts/RHKZKU71WG", {
  token: "CHEC_API_TOKEN",
  method: "DELETE",
}).then((res) => console.log(res));
`}</code></pre>
    <h2>{`Under the hood`}</h2>
    <p>{`As you can probably imagine, there isn't much going on in this dependency under the hood.`}</p>
    <p>{`It accepts an endpoint, API public key, and forwards your request (with optional data), setting the correct `}<inlineCode parentName="p">{`method`}</inlineCode>{` on its way.`}</p>
    <p>{`The bulk of the work happens in the internal `}<inlineCode parentName="p">{`request`}</inlineCode>{` function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`async request({
    endpoint,
    params = {},
    method = undefined,
    data = undefined,
  }) {
    const { baseUrl, version } = this.options;
    const headers = this.headers;

    const url = new URL(\`\${baseUrl}/\${version}/\${endpoint}\`);

    if (Object.keys(params)) url.search(new URLSearchParams(params));

    const response = await fetch(url, {
      headers,
      ...(method && { method }),
      ...(data && { body: JSON.stringify(data) }),
    });

    const json = await response.json();

    if (!response.ok) throw json;

    return json;
  }
`}</code></pre>
    <p>{`That's it!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      